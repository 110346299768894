<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 09:38:35
 * @LastEditTime: 2020-04-17 17:33:19
 -->
<template>
  <div class="jepaas-local">
    <div class="platform-title">
      国产化软件适配
    </div>
    <div class="platform-sub-title">
      支持国产，共同发展。目前已支持各类国产化适配。
    </div>
    <div style="font-size: 0">
      <img
        class="localimg animated slideInUp faster"
        v-lazy="local"
      >
    </div>

    <div class="icon-title">
      <div class="circle1" />
      <div class="circle2" />
      相关信息
    </div>

    <img
      class="zsimg"
      v-lazy="zs1"
    >
    <img
      class="zsimg"
      style="margin: 50px 0"
      v-lazy="zs2"
    >
    <img
      class="zsimg"
      style="margin-bottom: 50px"
      v-lazy="zs3"
    >
  </div>
</template>

<script>
import local from '@/assets/newHomeImg/home/local.svg';
import local_img from '@/assets/newHomeImg/platform/local-2.png';
import local_2_img from '@/assets/newHomeImg/platform/local-1.png';
import zs1 from '@/assets/newHomeImg/platform/zs1.jpeg';
import zs2 from '@/assets/newHomeImg/platform/zs2.jpeg';
import zs3 from '@/assets/newHomeImg/platform/zs3.jpeg';

export default {
  name: 'Local',
  props: {

  },
  data() {
    return {
      local,
      local_img,
      local_2_img,
      zs1,
      zs2,
      zs3,
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang='less'>
.jepaas-local {
  margin: 0 auto;
  width: 1250px;
  text-align: center;
  .platform-title {
    font-size: 30PX;
    font-weight: 600;
  }
  .platform-sub-title{
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .localimg {
    width: 100%;
  }
  .zsimg {
    width: 700px;
  }
  .icon-title {
    font-size: 24px;
    font-weight: 600;
    padding: 30px 50px;
    position: relative;
    padding-top: 100px;
    box-sizing: border-box;
    text-align: left;
    .circle1 {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #FFC5A6;
      position: absolute;
      top: 110px;
      left: 2px;
    }
    .circle2 {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #FF6C1E;
      position: absolute;
      top: 110px;
      left: 14px;
    }
  }
}
</style>
