<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 09:38:48
 * @LastEditTime: 2020-04-14 22:47:01
 -->
<template>
  <div class="jepaas-saas">
    <div class="platform-title">
      提供一整套可用的SaaS体系
    </div>
    <div class="platform-sub-title">
      JEPaaS可以解决在SaaS系统生产中的诸多技术问题，例如:大集群中负载均衡问题、大并发量访问性 能迟缓问题、数据隔离与安全性问题、文件的统一化管理问题、调度的统一化协调问题、客户的个性化需 求定制问题等等。在JEPaaS的支撑下，用户可 以快速的搭建出诸如CRM、OA、ERP、HIS、MES等SaaS应用系统。
    </div>
    <img
      class="dataimg animated slideInUp faster"
      v-lazy="saas"
    >

    <div class="platform-title">
      开发架构
    </div>
    <div class="platform-sub-title">
      JEPaaS拥有的独立数据库有助于简化数据模型的扩展设计，满足不同租户的独特需求；另一方面，共享数据库，隔离数据架构，为安全性要求较高的租户提供了一定程度的逻辑数据隔离，每个数据库可以支持更多的租户数量。
    </div>
    <div class="imgs">
      <img
        v-lazy="saas1"
        alt=""
      >
    </div>

    <div class="platform-title">
      快速搭建及上架
    </div>
    <div class="platform-sub-title">
      JEPaaS提供了丰富的平台资源可助您快速创建自己的SaaS应用，还能通过平台的商城进行上架授权，JEPaaS并提供运营监控机制。
    </div>
    <div class="imgs">
      <img
        v-lazy="saas2"
        alt=""
      >
    </div>

    <div class="platform-title">
      运营管理
    </div>
    <div class="platform-sub-title">
      通过JEPaaS的SaaS运营管理体系，可以对产品进行灵活管理：自定义产品信息、价格、产品功能范围等；同时，商城提供按照时间、人员的购买方式，并支持在线支付。并且还可以及时了解您的SaaS产品运营状况，直观的数据展示能助您及时调整运营策略。
    </div>
    <div class="imgs">
      <img
        v-lazy="saas3"
        style="margin-bottom: 50px"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import saas from '@/assets/imgs/home/kaifa.png';
import saas1 from '@/assets/newHomeImg/platform/saas-1.jpg';
import saas2 from '@/assets/newHomeImg/platform/saas-2.png';
import saas3 from '@/assets/newHomeImg/platform/saas-3.png';

export default {
  name: 'Saas',
  data() {
    return {
      saas,
      saas1,
      saas2,
      saas3,
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang='less'>
.jepaas-saas {
  margin: 0 auto;
  width: 1250px;
  .platform-title {
    padding-top: 100px;
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    &:first-child {
      padding-top: 0;
    }
  }
  .platform-sub-title{
    margin-top: 15px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
  }
  .dataimg {
    width: 100%;
  }
  .icon-title {
    font-size: 24px;
    font-weight: 600;
    padding: 30px 50px;
    position: relative;
    .circle1 {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #FFC5A6;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 2px;
    }
    .circle2 {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #FF6C1E;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 15px;
    }
  }
  .imgs {
    width: 100%;
    font-size: 0;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
