<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 09:39:12
 * @LastEditTime: 2020-04-14 10:56:35
 -->
<template>
  <div class="jepaas-api">
    <div class="platform-title">
      完善的对外接口
    </div>
    <div class="platform-sub-title">
      JEPAAS支持多种方式对外提供接口，如Http，Restful，WebService，第三方数据源，JdbcUtil。方便开发人员快速的提供及获取相关API数据。
    </div>
    <img
      class="apiimg1 animated slideInUp faster"
      v-lazy="api"
      alt=""
    >

    <div class="platform-title">
      强大接口 实现万物互联
    </div>
    <div
      class="platform-sub-title"
    >
      JEPaaS的大量的工业接口服务可供业务系统调用，例如：MODBUS、DCS、PLC、SCADA、OPC等。这些强大的数据接口可以从“数据感知层”中读取高价值数据并传输到“数据应用层”加以利用，最终形成万物互联。
    </div>
    <div class="doubleimg">
      <div
        v-for="(item, index) in doublelist"
        :key="index"
      >
        <img
          :src="item.img"
          alt
        >
        <p>{{ item.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/newHomeImg/home/api.svg';
import imgth_l from '@/assets/imgs/product/imgth_l.png';
import imgth_r from '@/assets/imgs/product/imgth_r.png';

export default {
  name: 'Api',
  props: {

  },
  data() {
    return {
      api,
      doublelist: [
        {
          img: imgth_l,
          label: '接口引擎设计图',
        },
        {
          img: imgth_r,
          label: 'JEPaaS对互联网的支撑',
        },
      ],
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang='less'>
.jepaas-api {
  margin: 0 auto;
  width: 1250px;
  box-sizing: border-box;
  text-align: center;
  .platform-title {
    padding-top: 100px;
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    &:first-child {
      padding-top: 0;
    }
  }
  .platform-sub-title{
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .apiimg {
    width: 100%;
  }
  .apiimg1 {
     width: 100%;
  }
  .doubleimg {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    > div {
      width: 508px;
      > img {
        width: 508px;
        height: 467px;
      }
      > p {
        font-size: 18px;
        margin-top: 40px;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>
