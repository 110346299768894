<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 08:41:26
 * @LastEditTime: 2020-04-15 15:47:17
 -->
<template>
  <div class="jepaas-platform">
    <div class="box_top">
      <Header />
    </div>
    <div class="je-container">
      <el-tabs
        v-model="activeName"
        stretch
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :label="item.label"
          :name="item.name"
          lazy
        >
          <div v-if="item.name == 'DDMKSKF'">
            <Code />
          </div>
          <div v-else-if="item.name == 'SZHZTGJ'">
            <Digitization ref="dataTab" />
          </div>
          <div v-else-if="item.name == 'GCHRJSP'">
            <Local />
          </div>
          <div v-else-if="item.name == 'SAAS'">
            <Saas />
          </div>
          <div v-else-if="item.name == 'MOBILE'">
            <Mobile />
          </div>
          <div v-else>
            <Api />
          </div>
          <Footer />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer.vue';
import Digitization from './components/data';
import Api from './components/api';
import Code from './components/code';
import Local from './components/local';
import Mobile from './components/mobile';
import Saas from './components/saas';

export default {
  name: 'Platform',
  components: {
    Header,
    Footer,
    Digitization,
    Api,
    Code,
    Local,
    Mobile,
    Saas,
  },
  data() {
    return {
      activeName: 'DDMKSKF',
      startPlay: false,
      tabList: [
        { name: 'DDMKSKF', label: '低代码开发' },
        { name: 'SZHZTGJ', label: '数字化中台' },
        { name: 'GCHRJSP', label: '国产化适配' },
        { name: 'SAAS', label: 'SaaS 搭建' },
        { name: 'MOBILE', label: '微信、钉钉、飞书' },
        { name: 'API', label: '开放、对接、IOT' },
      ],
      interval: null,
    };
  },
  mounted() {
    this.activeName = this.$route.params.activeName || 'DDMKSKF';
    this.startPlay = this.$route.params.play || false;
    if (this.startPlay) {
      this.$nextTick(() => {
        this.$refs.dataTab[0].videoPlay();
      });
    }
  },
  methods: {
    handleClick() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../style/common/header.less";
@import "../../../style/feature/title.less";
</style>
<style lang='less'>
.jepaas-platform {
  .je-container {
    background:url('../../../../assets/newHomeImg/platform/platform-bg.png') repeat-y 0 80px;
    background-size: 100% auto;
    background-color: #fff;
    .el-tabs__header {
      box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.29);
      padding: 0 300px;
      position: fixed;
      top: 80px;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 10;
      box-sizing: border-box;
    }
    .el-tabs__content {
      overflow: auto;
      padding-top: 110px;
    }
    .el-tabs__item {
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: 600;
      color: #3f3f3f;
    }
    .el-tabs__item.is-active {
      color: #F54D4E;
    }
    .el-tabs__active-bar {
      background-color: #F54D4E;
      height: 3px;
    }
    .el-tabs__nav-wrap::after {
      height: 0;
    }
  }
}
</style>
