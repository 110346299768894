<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 09:38:03
 * @LastEditTime: 2020-09-01 13:39:05
 -->
<template>
  <div class="jepaas-code">
    <div class="platform-title">
      低代码快速开发
    </div>
    <div class="platform-sub-title">
      低代码快速开发各种企业管理系统，时间快，成本低，11年经验沉淀，靠谱！
    </div>

    <div class="img-sm">
      <img
        class="fwImg animated slideInUp faster"
        v-lazy="framework"
        alt=""
      >
      <div class="sm">
        <div class="platform-title">
          JEPaaS架构图
        </div>
        <div class="cont">
          JEPaaS是一款优秀的软件平台产品，可视化开发环境，低代码拖拽式配置开发，操作极其简单，可以帮助解决Java项目80%的重复工作，让开发更多关注业务逻辑，大大提高开发效率，能帮助公司大幅节省人力成本和时间成本，同时又不失灵活性。适用于搭建 OA、ERP、CRM、HR、HIS等所有的企业信息管理系统（且适用于集团公司部署）。
        </div>
        <el-button
          type="danger"
          plain
          round
          size="medium"
          @click="goShop"
        >
          快速购买
          <i class="el-icon-caret-right el-icon--right" />
        </el-button>
        <el-button
          class="defaultButton"
          plain
          round
          size="medium"
          @click="goOpenUrl"
        >
          视频讲解
          <i class="el-icon-caret-right el-icon--right" />
        </el-button>
      </div>
    </div>

    <div class="platform-title">
      表结构自定义
    </div>
    <div class="platform-sub-title">
      JEPaaS平台支持oracle，sqlserver、mysql等
      主流数据库支持包括树形表、单表、主子表、
      视图在内的多种表类型，可以将表、字段、主
      外键、索引统一管理，利用工具快速建表以及
      实现对表的维护与更新。
    </div>
    <div class="imgs">
      <img
        v-lazy="codeimg"
        alt=""
      >
    </div>

    <div class="platform-title">
      功能自定义
    </div>
    <div class="platform-sub-title">
      JEPaaS平台支持功能、图表、报表、展板、新闻、自定义插件、HTML插件等在内的多种板块自行规划、灵活添加、随时调整，实现门户的个性化布局。
    </div>
    <div class="imgs">
      <img
        v-lazy="func2"
        alt=""
      >
      <img
        v-lazy="table1"
        style="margin-top: 50px"
        alt=""
      >
      <img
        v-lazy="table3"
        style="margin-top: 50px"
        alt=""
      >
    </div>

    <div class="platform-title">
      便携流程设计
    </div>
    <div class="platform-sub-title">
      JEPaaS的工作流引擎采用可视化编辑，提供包括串行、并行、多分支、嵌套等多种形式等流程设计，并支持流程版本控制、消息提醒、审批记录、流程流转事件，帮助开发人员设计出专业的流程功能，提高工作效率。
    </div>
    <div class="imgs">
      <img
        v-lazy="flow1"
        alt=""
      >
      <img
        v-lazy="flow2"
        alt=""
      >
    </div>

    <div class="platform-title">
      统一文件管理
    </div>
    <div class="platform-sub-title">
      文件库提供网盘功能，可实现文件快速上传管理。该文件库贯穿整个系统，在指定业务选文件可选文件，指定业务文件需要保留也可直接保存到网盘。
    </div>
    <div class="imgs">
      <img
        v-lazy="file"
        alt=""
      >
    </div>

    <div class="platform-title">
      BI引擎
    </div>
    <div class="platform-sub-title">
      JEPaaS的BI引擎可快速搭建面向全员的数据分析及BI平台，实现业务数据的可视化分析，充分了解并利用数据，辅助决策、提升业务。
    </div>
    <div class="imgs">
      <img
        v-lazy="BI1"
        alt=""
      >
    </div>

    <div class="platform-title">
      数据导入导出
    </div>
    <div class="platform-sub-title">
      JEPaaS支持Excel的数据导入功能，提供规划模版工具，可解析各种复杂的excel数据内容排版，将用户的excel数据直接导入到系统中。其中，导出主要提供列表的导出、打印格式的导入、也可以自己规划模版来将数据导出。
    </div>
    <div class="imgs">
      <img
        v-lazy="dataimg"
        alt=""
      >
    </div>

    <div class="platform-title">
      Web打印模块
    </div>
    <div class="platform-sub-title">
      JEPaaS提供打印模版快速规划工具，可将复杂的单据数据指定打印。便捷的打印工具，可实现更高效的办公体验。
    </div>
    <div class="imgs">
      <img
        v-lazy="print1"
        alt=""
      >
      <img
        v-lazy="print1_2"
        style="margin-bottom: 50px"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import framework from '@/assets/newHomeImg/platform/jg.svg';
import func2 from '@/assets/newHomeImg/platform/func2.png';
import func1_1 from '@/assets/newHomeImg/platform/func1-1.jpg';
import func1_2 from '@/assets/newHomeImg/platform/func1-2.jpg';
import func1_3 from '@/assets/newHomeImg/platform/func1-3.jpg';
import table1 from '@/assets/newHomeImg/platform/table1.png';
import table2 from '@/assets/newHomeImg/platform/table2.png';
import table3 from '@/assets/newHomeImg/platform/table3.png';
import flow1 from '@/assets/newHomeImg/platform/flow1.png';
import flow2 from '@/assets/newHomeImg/platform/flow2.png';
import flow_1 from '@/assets/newHomeImg/platform/flow1-1.png';
// import flow_2 from '@/assets/newHomeImg/platform/flow1-2.png';
import print1 from '@/assets/newHomeImg/platform/print1.png';
import print2 from '@/assets/newHomeImg/platform/print2.png';
import print1_1 from '@/assets/newHomeImg/platform/print1-1.png';
import print1_2 from '@/assets/newHomeImg/platform/print1-2.png';
import BI1 from '@/assets/newHomeImg/platform/BI1.png';
import BI2 from '@/assets/newHomeImg/platform/BI2.png';
import BI3 from '@/assets/newHomeImg/platform/BI3.png';
import file from '@/assets/newHomeImg/platform/file.svg';
import dataimg from '@/assets/newHomeImg/platform/data.svg';
import codeimg from '@/assets/newHomeImg/platform/code.svg';

export default {
  name: 'Code',
  props: {

  },
  data() {
    return {
      codeimg,
      framework,
      func2,
      func1_1,
      func1_2,
      func1_3,
      table1,
      table2,
      table3,
      flow1,
      flow2,
      flow_1,
      // flow_2,
      BI1,
      BI2,
      BI3,
      file,
      dataimg,
      print1,
      print2,
      print1_1,
      print1_2,
    };
  },
  mounted() {

  },
  methods: {
    goOpenUrl() {
      window.open('https://www.bilibili.com/video/BV1DV411C7gr/');
    },
    goShop() {
      this.$router.push({
        path: '/shop',
      });
    },
  },
};
</script>

<style lang='less'>
.jepaas-code {
  margin: 0 auto;
  width: 1250px;
  .platform-title {
    padding-top: 100px;
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    &:first-child {
      padding-top: 0;
    }
  }
  .platform-sub-title{
    margin-top: 15px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 35px;
  }
  .imgs {
    width: 100%;
    font-size: 0;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .img-sm {
    overflow: hidden;
    .fwImg {
      float: left;
      width: 820px;
    }
    .sm {
      float: right;
      width: 340px;
      text-align: left;
      .platform-title {
        text-align: left;
        padding: 90px 0 40px;
        font-size: 24px;
      }
      .cont {
        font-size: 14px;
        line-height:25px;
        margin-bottom: 10px;
      }
      .el-button {
        border-color:  #f56c6c;
        background-color: #f56c6c;
        color: #fff;
        &:hover {
          background-color: #fef0f0;
          color: #f56c6c;
        }
      }
      .defaultButton {
        margin-left: 20px;
        border-color:  #66b1ff;
        background-color: #66b1ff;
        color: #fff;
        &:hover {
          background-color: #fff;
          color: #66b1ff;
        }
      }
    }
    .leftImg {
      float: left;
      position: relative;
      font-size: 0;
      .func1-1-Img {
        width: 1000px;
      }
    }
    .fileImg {
      width: 720px;
    }
  }
  .shadow-box {
    box-shadow:0px -2px 19px 4px rgba(83,83,83,0.1);
    border-radius:10px;
  }
}
</style>
