<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 09:38:14
 * @LastEditTime: 2020-09-01 13:39:25
 -->
<template>
  <div class="jepaas-data">
    <div class="platform-title">
      强大的企业数字化中台
    </div>
    <div class="platform-sub-title">
      JEPaaS将原有分散的企业应用系统通过以通信服务、数据库操作、应用式配置中心、认证中心等在内的多种基础组件进行快速对接，基于多维
      内存数据库技术在数据架构层实现数据信息的汇总，形成标准化数据，通过统一数据实现所有相关业务系统的集中管理。
    </div>
    <div class="vedio_wrp animated slideInUp faster">
      <video
        ref="video"
        src="https://crm-public.oss-cn-beijing.aliyuncs.com/video/JEpaas-Propaganda.mp4"
        preload="auto"
        controls="controls"
        class="video"
      />
      <div
        v-if="vedioInit"
        class="mask init_mask"
        @click="videoPlay"
        @mouseover="isplayhover = true"
        @mouseout="isplayhover = false"
      >
        <img
          :src="home_vedio_init_bg"
          class="init_bg"
        >
        <img
          :src="isplayhover?playhover:play"
          alt=""
          class="play"
        >
      </div>
      <div
        v-show="videoMask == 'pause'"
        class="mask video_mask"
        @click="videoPlay"
        @mouseover="isplayhover = true"
        @mouseout="isplayhover = false"
      >
        <img
          :src="isplayhover?playhover:play"
          alt=""
          class="play"
        >
      </div>
    </div>
    <div class="icon-title">
      <div class="circle1" />
      <div class="circle2" />
      相关信息
    </div>

    <div style="font-size: 0">
      <img
        class="dataimg"
        style="margin-bottom: 50px"
        v-lazy="data_img"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import data_img from '@/assets/newHomeImg/platform/dataimg.jpg';
import play from '@/assets/imgs/home/home_play.png';
import playhover from '@/assets/imgs/home/home_play_hover.png';
import home_vedio_init_bg from '@/assets/imgs/home/home_vedio_init_bg2.jpg';

export default {
  name: 'Digitization',
  data() {
    return {
      data_img,
      videoMask: 'pause', // 播放状态
      vedioInit: true,
      play,
      playhover,
      isplayhover: false,
      home_vedio_init_bg,
    };
  },
  mounted() {
    const that = this;
    const myVideo = document.getElementsByClassName('video')[0];
    myVideo.addEventListener('play', () => {
      that.$set(that, 'videoMask', 'play');
    });

    myVideo.addEventListener('pause', () => {
      that.$set(that, 'videoMask', 'pause');
    });
  },
  methods: {
    // 点击播放按钮
    videoPlay() {
      this.vedioInit = false;
      this.$refs.video.play();
    },
  },
};
</script>

<style lang='less'>
.jepaas-data {
  margin: 0 auto 15px auto;
  width: 1250px;
  .platform-title {
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
  }
  .platform-sub-title{
    margin-top: 15px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
  }
  .vedio_wrp {
    width: 100%;
    font-size: 0;
    box-shadow:0px 10px 9px 1px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    > .video {
      width: 100%;
      z-index: 1;
      object-fit: contain;
      border-radius: 20px;
      overflow: hidden;
    }
    > .mask {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      > .play {
        width: 200px;
        height: 200px;
        z-index: 4;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    > .init_mask {
      z-index: 3;
      border-radius: 20px;
      overflow: hidden;
      > .init_bg {
        border-radius: 20px;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    > .video_mask {
      z-index: 2;
      background: rgba(0, 0, 0, .4);
    }
  }
  .dataimg {
    width: 100%;
  }
  .icon-title {
    font-size: 24px;
    font-weight: 600;
    padding: 30px 50px;
    position: relative;
    padding-top: 100px;
    box-sizing: border-box;
    .circle1 {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #FFC5A6;
      position: absolute;
      top: 110px;
      left: 2px;
    }
    .circle2 {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #FF6C1E;
      position: absolute;
      top: 110px;
      left: 14px;
    }
  }
}
</style>
