<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-03 09:39:01
 * @LastEditTime: 2020-04-14 19:31:07
 -->
<template>
  <div class="jepaas-mobile">
    <div class="platform-title">
      支持微信、钉钉、飞书、APP
    </div>
    <div class="platform-sub-title">
      打通微信、钉钉、飞书、APP、H5，互联互通，高效办公！
    </div>

    <img
      class="cpimg animated slideInUp faster"
      v-lazy="mobile"
    >

    <div class="platform-title">
      APP快速开发
    </div>
    <div class="platform-sub-title">
      JEPaaS提供在线的App开发工具，使用配置方式可快速开发出APP的多种功能。其中，可进行菜单的快速规划授权，支持功能、插件、图表、报表、链接等多种功能类型，并可通过列表使用自定义HTML快速开发、表单快速配置、查询快速配置的功能开发。
    </div>
    <div class="imgs">
      <img
        v-lazy="mobile1"
      >
    </div>

    <div class="platform-title">
      钉钉微信对接
    </div>
    <div class="platform-sub-title">
      JEPaaS集成钉钉与企业微信，通过组织架构的同步对接。可实现消息提醒、微信办公、钉钉办公，让您的工作更高效更轻松。
    </div>
    <div class="imgs">
      <img
        v-lazy="mobile3"
        style="margin-bottom: 50px"
      >
    </div>
  </div>
</template>

<script>
import mobile from '@/assets/newHomeImg/home/phone.svg';
import mobile1 from '@/assets/newHomeImg/platform/mobile1.png';
import mobile2_1 from '@/assets/newHomeImg/platform/mobile2-1.png';
import mobile2_2 from '@/assets/newHomeImg/platform/mobile2-2.png';
import mobile3 from '@/assets/newHomeImg/platform/mobile3.png';

export default {
  name: 'Mobile',
  data() {
    return {
      mobile,
      mobile1,
      mobile2_1,
      mobile2_2,
      mobile3,
    };
  },
};
</script>

<style lang='less'>
.jepaas-mobile {
  margin: 0 auto;
  width: 1250px;
  .platform-title {
    padding-top: 100px;
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    &:first-child {
      padding-top: 0;
    }
  }
  .platform-sub-title{
    margin-top: 15px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
  }
  .cpimg {
    margin-top: 20px;
    width: 100%;
  }
  .imgs {
    width: 100%;
    font-size: 0;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
